/* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap'); */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
body {
  font-family: 'Titillium Web', sans-serif;
  overflow-x: hidden;
}
h6, span, button, label, h2, h3, h4, h5 {font-family: 'Titillium Web', sans-serif !important }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #F1F2ED;
  border-radius: 4px;
}

/* For Firefox */
/* Note: Firefox does not support customization of scrollbar width */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
*::-moz-scrollbar-track {
  background-color: #f1f1f1;
}
*::-moz-scrollbar-thumb {
  background-color: #F1F2ED;
  border-radius: 4px;
}



table {
  border: none;
}


span.bars {
  height: 2px;
  background-color: #111;
  border-radius: 20px;
  width: 20px;
  display: block;
  margin: 0 0 3px;
}


@media(max-width:1023px){
.clicked ~ .sidebar {
  left: -3px ;
  position: fixed;
    overflow: scroll;
    height: 100%;

}
.clicked ~ .toogle-space {
  position: fixed;
}
.sidebar{
 z-index: 99999;
    transition: all 0.5s ease-in-out;
    left: -100%;
    width: 245px ;
  }
}

.MuiTableRow-hover {
  cursor: pointer;
}