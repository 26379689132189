.lost-pet-upload-dialog-container {
  padding: 0px 8px;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  width: 75px;
  height: 75px;
  color: #aaa;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.drag-drop-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 8px;
  border-radius: 5px;
}

.preview {
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid #ddd;
}
