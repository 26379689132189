.overlay-image {
  position: absolute;
  top: 40%;
  left: 40%;
  background-image: url("../../../asset/stamp.svg");
  background-size: cover;
  text-align: center;
}


.reward-desk {
  position: relative;
  background-image: url("../../../asset/stamp.svg");
  background-size: cover;
  text-align: center;
  transform: rotate(-20deg);
  display: none; /* Hide by default */
}

/* Media query for screens greater than 800px */
@media (min-width: 1021px) {
  .reward-desk {
    display: block; /* Show on screens greater than 800px */
  }
}


.reward-mobile {
  position: relative;
  background-image: url("../../../asset/stamp.svg");
  background-size: cover;
  text-align: center;
  transform: rotate(-20deg);
  display: none; /* Hide by default */
}

/* Media query for screens greater than 800px */
@media (max-width: 951px) {
  .reward-mobile {
    display: block; /* Show on screens greater than 800px */
  }
}

.overlay-text {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  color:#FC0000;
  font-weight: bold;
  line-height: 1;
}