.upload-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.icon-upload-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.drag-drop-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 8px;
  border-radius: 5px;
}

.icon-preview {
  width: 150px;
  /* height: 150px; */
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid #ddd;
  aspect-ratio: 1;
}
