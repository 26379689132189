.chat-head {
  z-index: 1001;
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
  background-color: white;
  transition: all 0.2s ease-in;
}

.chat-head .icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-head:hover {
  height: 60px;
  width: 60px;
}

.chat-head .chat-popup {
  z-index: 1111;
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  height: 600px;
  width: 400px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
}

.chat-popup.visible {
  display: block;
}

.chat-popup .chat-popup-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}

/* -------------- Header -------------- */
.chat-popup-body .chat-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.chat-popup-header .header-avatar img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.chat-popup-header .header-info {
  flex: 1;
  text-align: left;
  margin: 0 10px;
}

.chat-popup-header .header-options {
  text-align: right;
  opacity: 0.6;
}

.chat-popup-header .header-options:hover {
  cursor: pointer;
  opacity: 0.9;
}

/* -------------- Header End -------------- */


/* -------------- Body -------------- */

.chat-popup-body .chat-popup-messages {
  flex: 1;
  overflow-y: scroll;
  padding: 4px;
  display: flex;
  flex-direction: column;
}

.chat-popup-messages::-webkit-scrollbar{
  display: none;
}

.chat-popup-messages .message {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
  font-size: 0.9rem;
  padding: 8px;
  margin: 5px 0;
  max-width: 90%;
}

.sent {
  text-align: right;
  align-self: flex-end;
}

.received {
  text-align: left;
  align-self: flex-start;
}

/* -------------- Body End -------------- */

/* -------------- Footer -------------- */
.chat-popup-body .chat-popup-footer {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  display: flex;
}

.chat-popup-footer .input-wrapper {
  flex: 1;
  margin-right: 10px;
}

.input-wrapper input{
 height: 100%;
 width: 100%;
 padding: 0 8px;
}

.input-wrapper input:focus{
  outline-color: #ED1690;
}

.chat-popup-footer .send-btn {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #ED1690;
  opacity: 0.9;
  transition: all 0.2s ease-in;
  color: white;
}

.send-btn:hover {
  cursor: pointer;
  opacity: 1;
}

/* -------------- Footer End -------------- */

/* -------------- Additional -------------- */
.hidden {
  display: none;
}

.separator {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.separator .line{
  flex: 1;
  border-top: solid 1px rgba(0, 0, 0, 0.04);
}

.separator .time{
  font-size: 12px;
  opacity: .4;
}

/* -------------- End Additional -------------- */

@media only screen and (max-width: 768px) {
  .chat-head .chat-popup {
    height: 600px; 
    width: 350px;  
    bottom: 0;
    right: 0;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
  }
}


@media only screen and (max-width: 300px) {
  .chat-head .chat-popup {
    height: 500px; /* Adjust height for mobile */
    width: 280px;  /* Adjust width for mobile */
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
  }
}
